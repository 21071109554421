// import request from '../utils/request'
import { getAction,postAction } from '../utils/request'

export function listBooks(data) {
  return getAction('/web/book/listBooks',data)
}

export function getBook(data) {
  return getAction('/web/book/getBook', data)
}

export function deleteBook(data) {
  return getAction('/web/book/deleteBook',data)
}

export function addBook(data) {
  return postAction('/web/book/addBook',data)
}

export function updateBook(data) {
  return postAction('/web/book/updateBook',data)
}