<template>
  <div class="wrap">
    <div class="box-top">
      <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" @click="add()">新增</el-button>
      <el-input prefix-icon="el-icon-search" v-model="mess" placeholder="请输入书名、作者查询" id="search" @keydown.enter.native="toSearch" clearable></el-input>
    </div>
    <!-- 列表 -->
    <common-table ref="commonTable" :dataSource="tableData" :columnsList="columns" :total="total" @currentChange="currentChange" @sizeChange="sizeChange">
      <template slot="activationCode" slot-scope="{record}">
        {{record.activationCode}} <span class="iconfont icon-erweima" @click="handleQR(record)"></span>
      </template>
      <template slot="action" slot-scope="{record}">
        <el-button @click="editButton(record)" type="text" style="color: #00788b;">编辑</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-popconfirm title="确定删除吗？" @confirm="confirm(record)">
          <el-button slot="reference" type="text" style="color: #b51c1b;">删除</el-button>
        </el-popconfirm>
      </template>
    </common-table>
     
    <!-- 弹窗 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="50%" :before-close="handleClose" center :close-on-press-escape="false" :close-on-click-modal="false">
      <ebook-modal v-if="dialogVisible" ref="ebookModal" :oldForm="formData"></ebook-modal>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 二维码弹窗 -->
    <el-dialog :title="qrtitle" :visible.sync="QRVisible" width="30%" :before-close="qrClose" center :close-on-press-escape="false" :close-on-click-modal="false">
      <div class="QRBox">
        <!-- <h2>{{qrtitle}}</h2> -->
        <vue-qr :text="imgUrl" :size="250"></vue-qr>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listBooks,deleteBook,addBook, updateBook } from "../../api/ebook";
import ebookModal from "./ebookModal.vue";
import { myMixins } from "../../mixins/myMixins.js";
import commonTable from "../../components/commonTable.vue";
import VueQr from 'vue-qr';
export default {
  name: "activityPublic",
  mixins: [myMixins],
  data() {
    return {
      id: JSON.parse(sessionStorage.getItem('userInfo')).id || '',
      formData:{},
      mess: "",
      title: '',
      total: 0,
      pageSize: 10,
      pageNo: 1,
      btnSize: 'mini',
      currentPage4: 1,
      dialogVisible: false,
      tableData: [],
      imgUrl: '',
      QRVisible: false,
      qrtitle: '',
      columns: [
        {
          title: '书名',
          key: 'bookName',
          align: 'center'
        },
        {
          title: '作者',
          key: 'bookAuthor',
          align: 'center'
        },
        {
          title: '激活次数',
          key: 'activationCount',
          align: 'center'
        },
        {
          title: '激活码',
          key: 'activationCode',
          align: 'center',
          scopedSlots: {
            columnSlot: 'activationCode'
          }
        },
        {
          title: '上架时间',
          key: 'createTime',
          align: 'center'
        },
        {
          title: '介绍',
          key: 'introduce',
          align: 'center',
          width: 470,
        },
        {
          title: '操作',
          align: 'center',
          key: 'action',
          scopedSlots: {
            columnSlot: 'action'
          },
          width: 120
        }
      ]
    };
  },
  components: {
    ebookModal,
    commonTable,
    VueQr
  },
  mounted() {
    this.loadData();
  },
  methods: {
    toSearch() {
      listBooks({search: this.mess}).then(res => {
        if(res.success) {
          this.tableData = res.result.records;
          this.total = res.result.total;
          this.pageNo = res.result.current;
          this.pageSize = res.result.size;
        }else {
          this.$message.error(res.message);
        }
      })
    },
    loadData() {
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      listBooks(data).then(res => {
        if(res.success) {
          if(res.result.records.length == 0 && res.result.total != 0) {
            this.pageNo = res.result.pages;
            this.loadData();
          }else {
            this.tableData = res.result.records;
            this.total = res.result.total;
            this.pageNo = res.result.current;
            this.pageSize = res.result.size;
          }
          this.$nextTick(() => {
            this.$refs.commonTable.$refs.table.doLayout();
          })
        }
      })
    },
    add() {
      this.formData = {};
      this.title = '新增电子书',
      this.dialogVisible = true;
    },
    submit() {
      this.$refs.ebookModal.getdatas();
      this.$refs.ebookModal.$refs.form.validate((valid) => {
        let formInfo = this.$refs.ebookModal.form;
        // console.log(formInfo);
        if (!valid) {
          return;
        } else {
          let request = null;
          if(formInfo.id){
            request = updateBook(formInfo);
          }else{
            request = addBook(formInfo);
          }
          request.then(res => {
            if(res.success) {
              this.$message.success(res.message);
              this.dialogVisible = false;
              this.loadData();
            }else {
              this.$message.error(res.message);
            }
          })
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    editButton(row) {
      this.formData = row;
      this.title = '编辑电子书';
      this.dialogVisible = true;
    },
    del(e) {
      // console.log(e);
      deleteBook({id: e.id}).then(res => {
        if(res.success) {
          this.$message.success(res.message);
          this.loadData();
        }else{
          this.$message.error(res.message);
        }
      })
    },
    // handleSizeChange(val) {
    //   this.pageSize = val;
    //   this.loadData();
    // },
    // handleCurrentChange(val) {
    //   this.pageNo = val;
    //   this.loadData();
    // },
    handleQR(e) {
      this.qrtitle = e.bookName;
      this.imgUrl = '4,'+e.activationCode;
      this.QRVisible = true;
    },
    qrClose() {
      this.QRVisible = false;
    },
    confirm(e) {
      this.del(e);
    }
  },
};
</script>

<style scoped lang="less">
@import "../../assets/css/commonMenu.less";
.iconfont{
  font-size: 18px;
  color: skyblue;
}
.QRBox{
  text-align: center;
}
</style>
