<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="书名：" prop="bookName">
        <el-input v-model="form.bookName" placeholder="请输入书名"></el-input>
      </el-form-item>
      <el-form-item label="作者：" prop="bookAuthor">
        <el-input v-model="form.bookAuthor" placeholder="请输入作者"></el-input>
      </el-form-item>
      <el-form-item label="电子书文件：" prop="bookDownloadLink">
        <el-upload
          class="upload-demo"
          :action="uploadEBook"
          accept="application/pdf"
          :headers="headers"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-success="bookSuccess"
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="bookList">
          <el-button size="small" type="primary">点击上传</el-button>
          <!-- <div slot="tip" class="el-upload__tip">只能上传pdf文件，且不超过500kb</div> -->
        </el-upload>
      </el-form-item>
      <el-form-item label="价格：" prop="bookPrice">
        <el-input v-model="form.bookPrice" placeholder="请输入价格"></el-input>
      </el-form-item>
      <el-form-item label="介绍：" prop="introduce">
        <el-input type="textarea" :maxlength="512" v-model="form.introduce" placeholder="请输入电子书介绍" :autosize="{ minRows: 2, maxRows: 4}"></el-input>
      </el-form-item>
      <el-form-item label="封面图：" prop="bookImage">
        <el-upload
          :action="uploadURL"
          list-type="picture-card"
          :multiple="true"
          :headers="headers"
          :limit="1"
          :on-exceed="handleExceedImage"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemoveImage"
          :on-success="imgSuccess"
          :file-list="bookImageList">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false" :append-to-body="true" :close-on-press-escape="false" :close-on-click-modal="false">
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'userEdit',
  props:['oldForm'],
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      uploadURL: baseURL + '/web/file/uploadImage',
      uploadEBook: baseURL + '/web/file/uploadBook',
      headers: {
        'X-Access-Token': sessionStorage.getItem('token'),
      },
      imageUrl: '',
      rules: {
        bookName: [
            { required: true, message: '请输入书名', trigger: 'blur' },
          ],
        bookAuthor: [{ required: true, message: '请输入作者', trigger: 'blur' }],
        introduce: [{required: true, message: '请输入介绍', trigger: 'blur'}],
        bookDownloadLink: [{ required: true, message: '请上传电子书文件', trigger: 'blur' }],
        bookPrice: [{ required: true, message: '请输入价格', trigger: 'blur' }],
        bookImage: [{ required: true, message: '请上传封面', trigger: 'blur' }],
      },
      form: {
        bookName: '',
        bookAuthor: '',
        bookPrice: '',
        bookImage: '',
        bookDownloadLink: '',
      },
      bookList:[],
      books:[],
      bookImageList: [],
      bookImages:[]
    }
  },
  mounted(){
    let form = JSON.parse(JSON.stringify(this.oldForm));
    if(form.bookDownloadLink) {
      let str = form.bookDownloadLink;
      let name = str.substring(str.lastIndexOf('/')+1);
      name = name.substring(0,name.indexOf('_'));
      let obj = {name,url:baseURL+str,fileUrl:str}
      this.bookList.push(obj);
    }
    if(form.bookImage) {
      let str = form.bookImage;
      let name = str.substring(str.lastIndexOf('/')+1);
      name = name.substring(0,name.indexOf('_'));
      let obj = {name,url:baseURL+str,fileUrl:str}
      this.bookImageList.push(obj);
    }
    this.form = form;
},
  methods: {
    handleRemove(file, fileList) {
      if(file.fileUrl) {
        for(let i = 0;i<this.bookList.length;i++) {
          if(this.bookList[i].fileUrl === file.fileUrl) {
            this.bookList.splice(i,1);
            break;
          }
        }
      }else{
        if(file.status == 'success' && file.response && file.response.result) {
          for(let i = 0;i< this.books.length;i++) {
            if(file.response.result == this.books[i]) {
              this.books.splice(i,1);
            }
          }
        }
      }
    },
    handleRemoveImage(file, fileList) {
      if(file.fileUrl) {
        for(let i = 0;i<this.bookImageList.length;i++) {
          if(this.bookImageList[i].fileUrl === file.fileUrl) {
            this.bookImageList.splice(i,1);
            break;
          }
        }
      }else{
        if(file.status == 'success' && file.response && file.response.result) {
          for(let i = 0;i< this.bookImages.length;i++) {
            if(file.response.result == this.bookImages[i]) {
              this.bookImages.splice(i,1);
            }
          }
        }
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    bookSuccess(response,file){
      if(response.success) {
        this.$message.success('上传成功! ');
        this.books =  [response.result];
      }else {
        this.$message.error(response.message);
      }
    },
    imgSuccess(response,file,fileList) {
      // console.log(response,file);
      if(response.success) {
        this.$message.success('上传成功! ');
        this.bookImages =  [response.result];
        // console.log(this.form.images);
      }else {
        this.$message.error(response.message);
      }
    },
    handlePreview(file) {
      // window.open(file.url);
      // console.log(file);
    },
    handleExceed() {
      this.$message.warning('只能上传一份电子书');
    },
    handleExceedImage(){
      this.$message.warning('只能上传一张封面图片');
    },
    getdatas(){
      let list = [];
      for(let item of this.bookList) {
        list.push(item.fileUrl);
      }
      for(let item of this.books) {
        list.push(item);
      }
      this.form.bookDownloadLink = list.join(',');
      list = [];
      for(let item of this.bookImageList) {
        list.push(item.fileUrl);
      }
      for(let item of this.bookImages) {
        list.push(item);
      }
      this.form.bookImage = list.join(',');
    }
  }
}
</script>

<style scoped lang="less">
  .el-select{
    width: 100%;
  }
  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #333;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  /deep/ .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #ccc;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
  }
  /deep/ .avatar {
    width: 60px;
    height: 60px;
    display: block;
  }
  /deep/ .el-textarea__inner {
    max-height: 100px;
  }
</style>